import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Navigate } from 'react-router-dom';
import './App.css';
import AudioButton from './AudioButton';
import { createThread, addMessageToThread, runAssistant, retrieveRun, listMessages, listSteps, listRuns } from './openaiClient';
import { MessageContentText, ThreadMessage } from 'openai/resources/beta/threads/messages/messages';
import { RunStep, RunStepsPage } from 'openai/resources/beta/threads/runs/steps';
import { Run } from 'openai/resources/beta/threads/runs/runs';
import { Thread } from 'openai/resources/beta/threads/threads';

export type Message = {
  user: string;
  text: string;
};

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/:path" element={<App />} />
        <Route path="/" element={<Navigate to="/fx" replace />} />
      </Routes>
    </Router>
  );
}

function App() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);
  const [lastResponse, setLastResponse] = useState<string | null>(null);    
  const [shouldFetchTTS, setShouldFetchTTS] = useState(false);

  const { path } = useParams<{ path?: string }>();
  const effectivePath = path || 'fx'; // Default to 'fx' if path is undefined

  let assistantId = '';

  if (effectivePath.toLowerCase() === 'fx') {
    assistantId = process.env.REACT_APP_ASSISTANT_ID_FX || '';
  } else if (effectivePath.toLowerCase() === 'cx') {
    assistantId = process.env.REACT_APP_ASSISTANT_ID_CX || '';
  }

  // Create a default object
  const defaultThread: Thread = {
    id: '',
    created_at: 0,
    metadata: undefined,
    object: 'thread'
  };

  // Define state to store thread data if needed
  const [thread, setThread] = React.useState<Thread>(defaultThread);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const newThread = await createThread();
        console.log(`New thread created with ID: ${newThread.id}`);
        setThread(newThread); // Save the thread data in state
      } catch (error) {
        console.error('Failed to create thread:', error);
      }
    };

    fetchData();
  }, []);    

  const checkExistingRun = async (threadId: string) => {
    let attempts = 0;
    const maxAttempts = 15; // Set a maximum number of attempts to avoid potential infinite loops
  
    try {
      console.log(`Checking for existing runs on thread ${threadId} for assistant ${assistantId}.`);
      const runs = await listRuns(threadId);
      const existingRun = runs.data.find(run => run.assistant_id === assistantId && run.status === 'in_progress');
  
      if (existingRun) {
        console.log(`Found an in-progress run with ID ${existingRun.id}.`);
        while (existingRun.status === 'in_progress' && attempts < maxAttempts) {
          attempts++;
          console.log(`Waiting for run to complete. Attempt ${attempts}/${maxAttempts}.`);
          await new Promise(resolve => setTimeout(resolve, 1500)); // Wait for 1.5 second
  
          const retrievedRun = await retrieveRun(threadId, existingRun.id);
          existingRun.status = retrievedRun.status;
          console.log(`Retrieved run status: ${existingRun.status}`);
        }
  
        if (existingRun.status !== 'in_progress') {
          console.log('Run completed.');
          return existingRun;
        } else {
          throw new Error(`Run did not complete after ${maxAttempts} attempts.`);
        }
      } else {
        console.log('No existing run found.');
        return null;
      }
    } catch (error) {
      console.error(`Error in checkExistingRun: ${error}`);
      throw error; // Rethrow the error after logging
    }
  };  

  const pollRetrieveRunAndListSteps = async (threadId: string, runId: string) => {
    let runStatus: string | undefined;
    let stepsStatus: string | undefined;
    let attempts = 0;
    let stepsResponse: RunStepsPage;
    let steps: RunStep[] | undefined;
    const maxAttempts = 15;
  
    try {
      while ((!runStatus || !stepsStatus) && attempts < maxAttempts) {
        console.log(`Attempt ${attempts + 1}: Retrieving run and steps...`);
  
        const run = await retrieveRun(threadId, runId);
        if (run && typeof run.status !== 'undefined') {
          runStatus = run.status;
          console.log(`Run status: ${runStatus}`);
        } else {
          console.error('No run status available');
          throw new Error('No run status available');
        }
  
        stepsResponse = await listSteps(threadId, runId);
        if (stepsResponse && stepsResponse.data) {
          steps = stepsResponse.data;
          console.log(`Retrieved ${steps.length} steps.`);
          if (steps.length > 0) {
            stepsStatus = steps[steps.length - 1].status;
            console.log(`Last step status: ${stepsStatus}`);
          }
        } else {
          console.error('No steps available');
          throw new Error('No steps available');
        }
  
        if (runStatus === 'completed' && steps && steps.length > 0) {
          if (stepsStatus === 'completed') {
            console.log('Run and steps completed successfully.');
            return steps;
          } else if (stepsStatus === 'failed') {
            throw new Error('Failed to retrieve steps.');
          }
        } else if (runStatus === 'failed') {
          throw new Error('Failed to retrieve run.');
        }
  
        attempts++;
        await new Promise(resolve => setTimeout(resolve, 1500)); // Wait for 1.5 second
      }
    } catch (error) {
      console.error(`Error in pollRetrieveRunAndListSteps: ${error}`);
      throw error; // Rethrow the error after logging
    }
  
    if (attempts >= maxAttempts) {
      throw new Error(`pollRetrieveRunAndListSteps: Max attempts reached (${maxAttempts}).`);
    }
  };     

  const pollListMessagesAndListRuns = async (threadId: string, runId: string, assistantId: string) => {
    let attempts = 0;
    const maxAttempts = 15; // Set a maximum number of attempts
  
    try {
      console.log(`Polling messages and runs for thread ${threadId} and run ${runId}.`);
  
      while (attempts < maxAttempts) {
        attempts++;
        console.log(`Attempt ${attempts}: Checking for completed or failed messages and runs.`);
  
        const messagesResponse = await listMessages(threadId);
        const messages: ThreadMessage[] | undefined = messagesResponse.data;
        const botMessage = messages.find(message => message.role === 'assistant' && message.run_id === runId);
  
        const runsResponse = await listRuns(threadId);
        const runs: Run[] | undefined = runsResponse.data;
        const existingRun = runs.find(run => run.id === runId);
  
        if (existingRun && existingRun.status === 'failed') {
          const lastError = existingRun.last_error?.message;
          console.log(`Run failed with error: ${lastError}`);
          return null;
        }
  
        if (botMessage && existingRun && existingRun.status === 'completed') {
          console.log('Found completed message and run.');
          return botMessage;
        }
  
        if (attempts >= maxAttempts) {
          console.log('Max attempts reached without finding completed message and run.');
          return null;
        }
  
        await new Promise(resolve => setTimeout(resolve, 1500)); // Wait for 1.5 seconds
      }
    } catch (error) {
      console.error(`Error in pollListMessagesAndListRuns: ${error}`);
      throw error;
    }
  };  
  
  const getResponse = async (userInput: string) => {
    try {
      console.log(`Adding user's message to thread ID ${thread.id}`);
      await addMessageToThread(thread.id, userInput);
  
      console.log(`Checking for an existing run in thread ID ${thread.id}`);
      const existingRun = await checkExistingRun(thread.id);
      
      if (existingRun) {
        console.log(`Using existing run with ID: ${existingRun.id}`);
        const messages = await listMessages(thread.id);
        const botMessage = messages.data.find(message => message.role === 'assistant' && message.run_id === existingRun.id);
        if (botMessage) {
          const messageContent = botMessage.content[0] as MessageContentText;  
          const botResponse = removeEncodedTextAndAsterisks(messageContent.text.value);  
          handleNewBotMessage(botResponse);
          return botResponse;
        } else {
          console.warn("No bot response found in existing run.");
          // Consider adding a fallback or retry mechanism here
        }
      }
      
      console.log(`No existing run found. Running assistant on new thread ID ${thread.id}`);
      const run = await runAssistant(thread.id, assistantId);
      console.log(`Assistant run created with ID: ${run.id}`);
      
      await pollRetrieveRunAndListSteps(thread.id, run.id);
  
      console.log(`Polling for messages and runs after initiating assistant run ID ${run.id}`);
      const botMessage = await pollListMessagesAndListRuns(thread.id, run.id, assistantId);
      
      if (botMessage) {
        const messageContent = botMessage.content[0] as MessageContentText;
        const botResponse = removeEncodedTextAndAsterisks(messageContent.text.value);
        handleNewBotMessage(botResponse);
        return botResponse;
      } else {
        throw new Error("No response from OpenAI after initiating new run.");
      }
    } catch (error) {
      console.error(`Error in getResponse: ${error}`);
      throw error; // Rethrow the error to be handled by the caller
    }
  };  
       
  useEffect(() => {
    async function fetchResponse() {
      try {
        const input = messages[messages.length - 1].text;
        const response = await getResponse(input);
        setMessages((messages) => [...messages, { user: "Bot", text: response }]);
        setLoading(false);
      } catch (error) {
        setMessages((messages) => [...messages, { user: "Bot", text: "Sorry, I'm having trouble connecting the the service. Please try again." }]);
        setLoading(false);
        console.error('Error:', error);
      }
    }
  
    if (messages.length > 0 && messages[messages.length - 1].user === 'User') {
      fetchResponse();
    }
  
    // Automatically scroll to bottom when a new message is added
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isLoading]);     

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("handleSubmit called");
    if (!value) return;
    
    const userMessage: Message = { user: "User", text: value };
    setMessages((messages) => [...messages, userMessage]);
    
    setLoading(true);
    setValue(''); // clear the input field
  };

  function removeEncodedTextAndAsterisks(input: string): string {
    const encodedStartBracket = decodeURIComponent('%E3%80%90');
    const encodedEndBracket = decodeURIComponent('%E3%80%91');
    const encodedDagger = decodeURIComponent('%E2%80%A0');
    const bracketRegex = new RegExp(`${encodedStartBracket}.*?${encodedDagger}source${encodedEndBracket}`, 'g');
    const asterisksRegex = /\*\*/g; // Regex to match double asterisks
    return input.replace(bracketRegex, '').replace(asterisksRegex, '');
  }

  // This function is called when there is a new bot response
  const handleNewBotMessage = (newMessage: string) => {
    setLastResponse(newMessage);
    setShouldFetchTTS(true); // Indicate that we need to fetch new TTS
  };

  // This function is passed to AudioButton and called when TTS fetching is done
  const handleFetchDone = () => {
    setShouldFetchTTS(false); // Reset the flag after fetching TTS
  };

  return (
    <div className="App">
      <header>
        <h1>CSS IMPACT Reference Guide</h1>
      </header>
      <div className="chat-box">
        {messages.map((message, index) => (
          <div key={index} className={`chat-message ${message.user}`}>
            {/* Use split and map to handle new lines */}
            {message.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line} <br />
              </React.Fragment>
            ))}
          </div>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-wrapper">
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={e => setValue(e.target.value)}
            className="input"
            required
          />
          {isLoading && 
            <div className="loading-message">
              <span></span><span></span><span></span>
            </div>
          }
        </div>
        <button type="submit">Send App</button>
      </form>
      {lastResponse && 
        <AudioButton 
        lastResponse={lastResponse}
        shouldFetchTTS={shouldFetchTTS}
        onFetchDone={handleFetchDone}
        />
      }
    </div>
  );  
}

export default AppWrapper; // Export AppWrapper instead of App