import React, { useState, useEffect } from 'react';

interface AudioButtonProps {
  lastResponse: string | null;
  shouldFetchTTS: boolean;
  onFetchDone: () => void;
}

const AudioButton: React.FC<AudioButtonProps> = ({ lastResponse, shouldFetchTTS, onFetchDone }) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(`shouldFetchTTS changed: ${shouldFetchTTS}`);
    if (shouldFetchTTS) {
      setAudioUrl(null);
    }   
  }, [shouldFetchTTS]);

  useEffect(() => {
    const newAudio = new Audio();
    setAudio(newAudio);

    return () => {
      newAudio.pause();
      newAudio.src = '';
    };
  }, []);

  useEffect(() => {
    if (audio) {
      audio.onended = () => {
        setPlaying(false);
      };
    }
  }, [audio]);

  const fetchTTS = async () => {
    setIsLoading(true);
    if (lastResponse && shouldFetchTTS) {
      console.log('Fetching TTS started');      
      try {
        const XI_API_KEY = process.env.REACT_APP_XI_API_KEY || '';
        const VOICE_ID = process.env.REACT_APP_VOICE_ID || '';
        const ttsUrl = `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}/stream`;

        const data = {
          "text": lastResponse,
          "model_id": "eleven_monolingual_v1",
          "voice_settings": {
            "stability": 0.5,
            "similarity_boost": 0.5
          }
        };

        const response = await fetch(ttsUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'xi-api-key': XI_API_KEY
          },
          body: JSON.stringify(data)
        });

        if (response.ok) {
          const blob = await response.blob();
          const newAudioUrl = URL.createObjectURL(blob);
          setAudioUrl(newAudioUrl);
          if (audio) {
            audio.src = newAudioUrl;
            audio.play();
            setPlaying(true);
            console.log('Fetching TTS ended');
            onFetchDone(); // Notify parent component that fetching is done
          }
        } else {
          console.error('Failed to fetch TTS.');
          onFetchDone(); // Notify parent component that fetching is done even if there's an error
        }
      } catch (error) {
        console.error('Error fetching TTS:', error);
        onFetchDone(); // Notify parent component that fetching is done even if there's an error
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const togglePlay = () => {
    if (!audioUrl && shouldFetchTTS) {
      fetchTTS();
    } else if (audio) {
      if (playing) {
        audio.pause();
      } else if (audioUrl) {
        audio.play();
      }
      setPlaying(!playing);
    }
  };

  let buttonLabel = playing ? 'Stop' : 'Play';
  if (isLoading) {
    buttonLabel = 'Working...';
  }

  return (
    <div>
      <button onClick={togglePlay}>{buttonLabel}</button>
    </div>
  );
};

export default AudioButton;
