import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI_KEY,
  dangerouslyAllowBrowser: true
});

const createThread = async () => {
  return await openai.beta.threads.create({});
};

const addMessageToThread = async (threadId: string, userMessage: string) => {
  return await openai.beta.threads.messages.create(threadId, {
    role: "user",
    content: userMessage
  });
};

const runAssistant = async (threadId: string, assistantId: string, additionalInstructions?: string) => {
  return await openai.beta.threads.runs.create(threadId, { 
    assistant_id: assistantId,
    instructions: additionalInstructions
  });
};

const retrieveRun = async (threadId: string, runId: string) => {
  return await openai.beta.threads.runs.retrieve(threadId, runId);
};

const listMessages = async (threadId: string) => {
  return await openai.beta.threads.messages.list(threadId);
};

const listSteps = async (threadId: string, runId: string) => {
    return await openai.beta.threads.runs.steps.list(threadId, runId);
};  

const listRuns = async (threadId: string) => {
    return await openai.beta.threads.runs.list(threadId);
};  

export {
  openai,
  createThread,
  addMessageToThread,
  runAssistant,
  retrieveRun,
  listMessages,
  listSteps,
  listRuns
};
